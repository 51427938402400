import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import AdminService from "../../../components/services/admin.service";
import "../../../assets/styles/admin/datatable.css";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import DelBin from "../../../assets/images/myregisteration/trash-fill.svg";
import { lStorage } from "../../../components/utils/storage";
import { navigate } from "gatsby";
import View from "../../../assets/images/successfulcards/view-icon.svg";

const CompanyRegTable = () => {
  const [customers, setCustomers] = useState();
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    poc_contact_number: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    registration_limit: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    company_code: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    pnr_no: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    company_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    poc_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    poc_email_id: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    status: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    payment_failed_reason: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    expiry_date: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();

  const adminServices = new AdminService();

  const getOrderedCompanyListing = () => {
    adminServices.getorderCompanylist().then((data) => {
      setCustomers(data?.data?.data);
    });
  };

  useEffect(() => {
    getOrderedCompanyListing();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
        <span>
          <span className="mr-1">
            {/* <button class="btn btn-primary" onClick={() => navigate('/admin/approveCompany')}>
            View
          </button> */}
          </span>
          <a onClick={() => addCompany()} class="btn btn-primary">
            Add Company
          </a>
        </span>
      </div>
    );
  };

  const confirmationPopup = (data) => {
    setSelectedCompany(data);
    setOpenModal(true);
  };

  const addCompany = () => {
    lStorage.set("selectedCom", "");
    navigate("/admin/addcompany");
  };
  const editCompany = (data) => {
    lStorage.set("selectedCom", data);
    navigate("/admin/addcompany");
  };

  const viewParticipant = (data) => {
    lStorage.set("setApprovalCompany", data);
    navigate("/admin/approveCompany");
  };

  const buttonTemplate = (data) => (
    <>
      <span
        type="button"
        role="button"
        tabIndex="0"
        onKeyDown={(e) => confirmationPopup(data)}
        onClick={(e) => confirmationPopup(data)}
        title="Delete"
      >
        <img className="img-fluid" src={DelBin} alt="Clock logo" />
      </span>
      <span
        type="button"
        role="button"
        tabIndex="0"
        className="ml-1"
        onKeyDown={() => editCompany(data)}
        onClick={() => editCompany(data)}
        title="Edit"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="15"
          fill="currentColor"
          class="bi bi-pen"
          viewBox="0 0 16 16"
        >
          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
        </svg>
      </span>
      <span
        type="button"
        role="button"
        tabIndex="0"
        className="ml-1"
        onKeyDown={() => viewParticipant(data)}
        onClick={() => viewParticipant(data)}
        title="Edit"
      >
        <img src={View} alt="" />
      </span>
    </>
  );

  const deleteCompany = (data) => {
    adminServices
      .deleteadminCompany(data?.company_id)
      .then((res) => {
        setOpenModal(false);
        toast.success(res?.data?.message);
        getOrderedCompanyListing();
      })
      .catch((err) => {
        setOpenModal(false);
        toast.error(err?.data?.message?.[0]);
      });
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const dateBodyTemplate = (rowData) => {
    if (rowData?.expiry_date !== null) {
      return moment(rowData?.expiry_date).format("DD-MM-YYYY HH:MM A");
    }
  };

  const StatusTemplate = (rowData) => {
    return rowData?.status === 1 ? "Active" : "Inactive";
  };

  const header = renderHeader();

  return (
    <div className="datatable-doc-demo">
      <h3 className="py-3 px-2"><b>Registered Companies</b></h3>
      <div className="card">
        <DataTable
          value={customers}
          paginator
          className="p-datatable-customers"
          header={header}
          rows={15}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[15, 30, 50]}
          dataKey="id"
          rowHover
          selection={selectedCustomers}
          onSelectionChange={(e) => setSelectedCustomers(e.value)}
          filters={filters}
          filterDisplay="menu"
          // loading={loading}
          responsiveLayout="scroll"
          stripedRows
          size="small"
          emptyMessage="No Records found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <Column
            field="company_name"
            header="Company Name"
            sortable
            style={{ width: "8rem" }}
            filter
            filterPlaceholder="Search by Name"
          />

          <Column
            field="poc_name"
            header="Contact Name"
            sortable
            style={{ width: "12rem" }}
            filter
            filterPlaceholder="Search by PNR Number"
          />
          <Column
            field="poc_email_id"
            header="Contact Email id"
            sortable
            style={{ width: "8rem" }}
            filter
            filterPlaceholder="Search by Email"
          />

          <Column
            field="poc_contact_number"
            header="Mobile Number"
            sortable
            filter
            style={{ width: "5rem" }}
          />
          <Column
            field="registration_limit"
            header="Max Reg. Count"
            sortable
            filter
            style={{ width: "8rem" }}
          />
          <Column
            field="company_code"
            header="Company Code"
            sortable
            filter
            style={{ width: "5rem" }}
          />
          <Column
            field="expiry_date"
            header="Expirydate"
            sortable
            filterField="expiry_date"
            dataType="date"
            style={{ minWidth: "5rem" }}
            body={dateBodyTemplate}
            filter
            filterElement={dateFilterTemplate}
          />
          <Column
            field="status"
            header="Status"
            sortable
            body={StatusTemplate}
            style={{ width: "3rem" }}
            filter
          />

          <Column
            header="Actions"
            headerStyle={{ width: "5rem", textAlign: "center" }}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
            body={buttonTemplate}
          />
        </DataTable>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
      <Modal
        show={openModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="register-popup"
      >
        <Modal.Header closeButton onClick={handleClose}></Modal.Header>
        <Modal.Body>
          <h4> Are you sure want to delete ?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="whiteColor" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => deleteCompany(selectedCompany)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CompanyRegTable;
